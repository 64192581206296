import React from 'react';
import Image from 'next/image';
import { Button } from '@components/ui/button';
import Link from 'next/link';
import { useSession } from '@hooks/index';
import { useAnimation } from 'framer-motion';
import PicStudioVideoSep2023 from './PicStudioVideoSep2023';
import { motion } from 'framer-motion';
import NumberOne from '@components/Badge/NumberOne';
import SocialProof from '@components/Cards/SocialProof';
import OriginalVsGenerated from './OriginalVsGenerated';

export default function LinkedinHero() {
  const { isLoggedIn } = useSession();
  const controls = useAnimation();

  React.useEffect(() => {
    controls.start({
      x: '-50%',
      transition: {
        repeat: Infinity,
        duration: 60,
        ease: 'linear'
      }
    });
  }, [controls]);

  return (
    <div className="bg-background relative isolate overflow-hidden  mb-6 lg:mb-16">
      <div className="mx-auto">
        {/* <div className="bg-red-500relative flex flex-col px-4 md:px-8 w-full m-auto gap-6 max-w-screen-2xl">
          <div className="flex flex-row gap-1 p-1 rounded-sm bg-white/90 w-fit absolute top-4 left-0">
            <Image
              src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3a2e3ea0-c19b-4741-8e1d-d43c2ad30500/public"
              alt={''}
              height={300}
              width={174}
              className="w-4"
            />
            <div className="text-blue-600 text-[10px] text-center font-bold">Made in the USA</div>
          </div>
        </div> */}
        <div className="absolute flex w-full top-0 left-0 right-0 h-full object-cover -z-40 bg-gradient-to-t from-background/90 via-background/90 to-background/70" />
        <motion.div
          className="absolute top-0 left-0 right-0 h-full object-cover -z-50 flex w-[300%] opacity-25"
          animate={controls}
        >
          <div className="w-[75%] h-full">
            <Image
              src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/c6875e6f-c7b5-4c8c-ce1a-bc0fa6c33500/full?q10'}
              layout="fill"
              objectFit="cover"
              alt="Hero"
              className="object-cover"
            />
          </div>
          <div className="w-[75%] h-full">
            <Image
              src={'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/c6875e6f-c7b5-4c8c-ce1a-bc0fa6c33500/full?q10'}
              layout="fill"
              objectFit="cover"
              alt="Hero"
              className="object-cover"
            />
          </div>
        </motion.div>
        <div className="bg-red-500/0 grid grid-cols-1 px-4 md:px-10 lg:px-20 m-auto pt-6 lg:py-10 gap-6 pb-0">
          <div className="bg-red-500/0 relative flex flex-col items-center justify-center p-6 w-full">
            <div className="mb-6 flex gap-10">
              <NumberOne />
            </div>
            <div className="mb-10">
              <OriginalVsGenerated />
            </div>

            <div className="relative flex flex-col m-auto w-full">
              <h1 className="text-2xl md:text-4xl lg:text-6xl xl:text-7xl font-bold tracking-normal text-primary text-center">
                Generate photos <br />
                of yourself with AI
                <div className="mt-2 text-xs lg:text-lg text-muted-foreground">(Photos that look 100% real)</div>
              </h1>

              <div className="mt-6 lg:mt-10 m-auto mx-0 flex-col w-full flex md:flex-row items-center justify-center gap-x-6 gap-y-4">
                {!isLoggedIn ? (
                  <div className="w-full lg:w-fit relative">
                    <div className="absolute -top-8 left:0 md:left-1/2 transform md:-translate-x-1/2">
                      <div className="bg-foreground text-background text-[10px] font-bold px-2 py-1 rounded-full relative  animate-bounce">
                        NEW VERSION
                        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-foreground"></div>
                      </div>
                    </div>
                    <Button className="w-full" variant={'accent'} size={'xl'} asChild>
                      <Link href="/#pricing">Get your portraits</Link>
                    </Button>
                  </div>
                ) : (
                  <div className="w-full lg:w-fit">
                    <Button className="w-full" variant={'accent'} size={'xl'} asChild>
                      <Link href="/dashboard" className="text-sm font-semibold leading-6 text-studio-900">
                        Go to dashboard
                      </Link>
                    </Button>
                  </div>
                )}
                <PicStudioVideoSep2023 />
              </div>
              <SocialProof />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
